.footer {
    position: fixed;
    bottom: 0;
    background-color: #3a3E41;
    width: 100%;
    text-align: center;
    padding: 20px;
}

.text {
    display: inline;
    color: white;
}

.register {
    margin: 0;
    display: inline;
    color: white;
    font-weight: bold;
    text-decoration: none;
}

.register:hover {
    color: #c30a17;
}