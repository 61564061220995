.container {
    margin-top: 130px;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    border: solid 2px black;
    border-radius: 10px;
    padding: 20px 100px 20px 100px;
}

.resend {
    cursor: pointer;
    display: block;
    font-weight: bold;
    margin-top: 10px;
}

.resend:hover {
    color: #c30a17;
}

.resend:active {
    color: #3a3E41;
}

.label {
    display: block;
    margin-top: 15px;
    margin-bottom: 7px;
}

.message {
    margin-top: 10px;
    max-width: 500px;
    min-height: 25px;
}

.info {
    font-size: 18px;
    color: green;
    overflow-wrap: break-word;
}

.error {
    font-size: 18px;
    color: red;
    overflow-wrap: break-word;
}

@media screen and (max-width: 520px) {
    .container{
        padding: 0 15% 0 15%;
        margin: 140px auto auto;
        width: auto;
        border: unset;
    }

}

@media screen and (max-width: 380px) {
    .container{
        padding: 0 10% 0 10%;
    }
}
