.label {
    display: block;
    margin-top: 15px;
    margin-bottom: 7px;
}

.box {
    display: block;
    margin-top: 15px;
    margin-bottom: 7px;
}

@media screen and (max-width: 520px) {
    .container{
        padding: 0 15% 0 15%;
        margin: 140px auto auto;
        width: auto;
        border: unset;
    }

}

@media screen and (max-width: 380px) {
    .container{
        padding: 0 10% 0 10%;
    }
}
