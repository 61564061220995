.link {
    color: #c30a17;
    text-decoration: none;
    font-weight: bold;
    text-align: center;
    margin: 20px;
    display: block;
}

.altlink {
    display: inline;
    color: #c30a17;
    text-decoration: none;
    font-weight: bold;
    text-align: center;
    margin: 20px;
}

.altlink:hover,
.link:hover {
    color: #3a3E41;
}

.progress {
    margin-left: 5px;
    margin-right: 10px;
}

.loadingInfo {
    display: flex;
    align-items: center;
}

