.rootContainer {
    margin-top: 130px;
    margin-bottom: 100px;
    max-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.image {
    width: 150px;
    height: 150px;
    margin: 20px;
    object-fit: contain;
    float: left;
}

.infoText {
    font-style: italic;
    min-height: 200px;
}

.headline {
    margin: 5px;
}

.infoContainer {
    margin: 15px;
    border: solid 2px black;
    border-radius: 20px;
    padding: 5px;
    min-height: 300px;
    max-height: 300px;
    min-width: 460px;
    max-width: 30%;
    background-color: lightgrey;
}

.linkContainer {
    text-align: center;
}

.infoContainer:nth-child(2n) {
    page-break-after: always;
}

@media screen and (max-width: 625px) {
    .headline{
        text-align: center;
    }

    .image{
        margin: 0 5px 0 0;
    }

    .infoContainer {
        min-width: 300px;
    }

    .rootContainer .infoContainer:nth-child(1n + 3) {
        min-height: 370px;
    }

    .rootContainer .infoContainer:nth-child(-n + 2) {
        min-height: 350px;
    }

}
