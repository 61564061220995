.container {
    margin-top: 130px;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    border: solid 2px black;
    border-radius: 10px;
    padding: 20px 100px 20px 100px;
}

.headline {
    font-weight: bold;
}

.message {
    font-style: italic;
}

.info {
    font-weight: bold;
}

.loadingContainer {
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
}

.messageContainer {
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    text-align: center;
}

.messageContainerWithHeaderSpace{
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    text-align: center;
    margin-top: 130px;
}

.changeLoading {
    display: inline;
    font-weight: bold;
}


@media screen and (max-width: 520px) {
    .container{
        padding: 0 15% 0 15%;
        margin: 140px auto auto;
        width: auto;
        border: unset;
    }

}

@media screen and (max-width: 380px) {
    .container{
        padding: 0 10% 0 10%;
    }
}
