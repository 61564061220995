.verificationContainer {
    margin-top: 130px;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    border: solid 2px black;
    border-radius: 10px;
    padding: 20px 100px 20px 100px;
}

.verificationHeadline {
    font-weight: bold;
}

.verificationMessage {
    font-style: italic;
}

.verificationInfo {
    font-weight: bold;
}

.verificationLoadingContainer {
    padding: 10px;
    margin: 20px;
}

.verificationLoading {
    display: inline;
    font-weight: bold;
}
