.container {
    margin-top: 130px;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    border: solid 2px black;
    border-radius: 10px;
    padding: 20px 100px 20px 100px;
}

.message {
    padding: 15px;
}

.label {
    display: block;
    margin-top: 15px;
    margin-bottom: 7px;
}

.error {
    margin-top: 10px;
    max-width: 350px;
    min-height: 25px;
    font-size: 18px;
    color: red;
    overflow-wrap: break-word;
}


@media screen and (max-width: 520px) {
    .container{
        padding: 0 15% 0 15%;
        margin: 140px auto auto;
        width: auto;
        border: unset;
    }

}

@media screen and (max-width: 380px) {
    .container{
        padding: 0 10% 0 10%;
    }
}

