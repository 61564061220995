.container {
    margin-top: 130px;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    border: solid 2px black;
    border-radius: 10px;
    padding: 20px 100px 20px 100px;
}

.home {
    cursor: pointer;
    display: block;
    font-weight: bold;
    margin-top: 10px;
    text-decoration: none;
    color: #c30a17
}

.home:hover {
    color: #3a3E41
}

.home:active {
    color: black;
}

@media screen and (max-width: 520px) {
    .container{
        padding: 0 15% 0 15%;
        margin: 140px auto auto;
        width: auto;
        border: unset;
    }

}

@media screen and (max-width: 380px) {
    .container{
        padding: 0 10% 0 10%;
    }
}
