.header {
    display: block;
    min-width: 100%;
    height: 100px;
    overflow: hidden;
    text-align: center;
    background-color: #3a3E41;
    color: #e8ebee;
    position: fixed;
    top: 0;
    align-items: center;
}

.header div {
    width: 100%;
}

.welcome {
    display: inline;
    font-size: 50px;
    line-height: 2.3;
}

.pageLogo2Gether {
    width: 200px;
    margin-left: 20px;
    vertical-align: bottom;
}

@media screen and (max-width: 625px) {
    .header{
        min-width: 400px;
        width: 100%;
    }

    .welcome {
        font-size: x-large;
        line-height: 3.4;
    }

    .pageLogo2Gether {
       width: 154px;
        margin-top: 15px;
    }
}
